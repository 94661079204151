/* 

This is the very top of the Resume Page. 

*/

const Navbar = () => {
    return (
        <nav className="Navbar" >
            <h1><a href="https://www.linkedin.com/in/brandon-torres-40ba35180/" target="_blank" rel="noopener">Brandon Tye Torres</a></h1>
            <div className="Links">
                <h1>Chicago, IL</h1>
               {/*} 

                INQUIRY BUTTON THAT WILL BE USED LATER

                <a href="/inquiry" style={{
                    color: "white",
                    backgroundColor: '#424fe3',
                    borderRadius: '20px'
                }}>Inquiry</a>
            */}



            </div>


        </nav>

      );
}
 
export default Navbar;
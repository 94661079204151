/* 

This is the main Resume Page. 

*/

const HomepageResume = () => {
    return ( 

        <div className="home">
      
            <h2><u>Work Experience</u></h2>


            <h3 className='WorkExp'>Microsoft | Systems Support Engineer (Dynamics GP)</h3>
            <h5 className='WorkExp'><u>Aug. 2022 - Feb. 2024 | Fargo, ND / Remote</u></h5>
            <ul className="BulletPoints"> 
                <li className="BulletPointSpacing">Provide expert-level support for the Microsoft Dynamics GP application, resolving tier 2 and 3 system
                    support, and tier 1 application support cases for Microsoft partners worldwide.</li>
                <li className="BulletPointSpacing">Develop and maintain technical documentation, including knowledge base articles and user guides, to help
                    partners and team members troubleshoot and resolve issues independently.</li>
                <li className="BulletPointSpacing">Utilize Dexterity Script Logs, SQL Profiler Traces, ProcMon Traces, and Network Traces to identify and
                    resolve issues effectively.</li>
                <li className="BulletPointSpacing">Utilize existing documentation to grasp Accounting to enhance my understanding of tier 1 application
                    support.</li>
            </ul>



            <h3 className='WorkExp'>Empire Today | Application Support Analyst</h3>
            <h5 className='WorkExp'><u>Jan. 2021 - Aug. 2022 | Northlake, IL / Remote</u></h5>
            <ul className="BulletPoints">
                <li className="BulletPointSpacing">Responsible for troubleshooting, ticket/incident updates, escalation, resolutions, and reporting of
                    incidents for Dynamics 365 and proprietary software.</li>
                <li className="BulletPointSpacing">Work closely with Software Engineers and QA Engineers prior to production release to ensure a smooth
                    transition into production.</li>
                <li className="BulletPointSpacing">Created in-depth technical documentation to be included in the company's knowledge base, using clear and
                    concise language, diagrams, and other visual aids to enhance understanding.</li>
                <li className="BulletPointSpacing">Utilized Azure Active Directory to manage end-user accounts, including provisioning and deprovisioning
                    accounts, managing access permissions, and ensuring compliance with current security policies.</li>
                    <li className="BulletPointSpacing">Developed an Access Request Application using Python.</li>
            </ul>


            <h3 className='WorkExp'>Northrop Grumman | Help Desk Administrator</h3>
            <h5 className='WorkExp'><u>Jan. 2020 - Aug. 2020 | Irving, TX / Remote</u></h5>
            <ul className="BulletPoints">
                <li className="BulletPointSpacing">Successfully supported 90,000+ users worldwide, including external employees from NASA, CDC, and Lockheed
                    Martin, by providing timely and effective technical support and troubleshooting.</li>
                <li className="BulletPointSpacing">Develop and maintain technical documentation, including knowledge base articles and user guides, to help
                    partners and team members troubleshoot and resolve issues independently.</li>
                <li className="BulletPointSpacing">Responded to support requests from end users and walked individuals through basic troubleshooting tasks.
                </li>
            </ul>


            <h2><u>Projects</u></h2>
            <ul className="BulletPoints Certifications">
                <li className="BulletPointSpacing">BTCloudWebApp.com | An ASP.NET 8.0 Web App hosted on Azure App Services. The site is equipped with a robust CI/CD Pipeline that automates the build, testing, and deployment processes, streamlining the development workflow. This setup ensures efficiency and reliability in managing the codebase.</li>
            </ul>


            <h2><u>Certifications</u></h2>
            <ul className="BulletPoints Certifications">
                <li className="BulletPointSpacing">Microsoft Certified: Azure Administrator Associate (AZ-104 | Completed 1/2024)</li>
                <li className="BulletPointSpacing">Microsoft Certified: Azure Fundamentals (AZ-900 | Completed 4/2023)</li>
                <li className="BulletPointSpacing">LPI Linux Essentials (010 | Completed 4/2022)</li>
                <li className="BulletPointSpacing">Cisco Certified Networking Associate (CCNA | Expired Aug. 2023)</li>
            </ul>

            <h2><u>Awards</u></h2>
            <ul className="BulletPoints Certifications">
                <li className="BulletPointSpacing">Recognized as Employee of the Month by my contracting agency in recognition of outstanding performance and contribution to team success (March 2023)</li>
                <li className="BulletPointSpacing">Closed the most cases in the IT department in 2021 (Empire Today)</li>
            </ul>

            <h2><u>Skills</u></h2>
            <p>
            ASP.NET, C#, React, Python, SQL, CLI, REST APIs, Networking (TCP/IP), Linux, Docker, Kubernetes, Ansible, HELM,
            Azure Administration and Development, Tier 3 Troubleshooting, Serverless, Monitoring, ARM Templates,
            BICEP Templates, Virtualization, Governance, Microsoft Entra ID, Role-Based Access Control (RBAC).
            </p>

            <h2><u>Website Information</u></h2>
            <p>This static website is built using a modern and robust technology stack that leverages React.js for the frontend, Azure Functions for serverless backend logic, CosmosDB for NoSQL database storage, and Azure for hosting and cloud services.</p>

        </div>
);
}
 
export default HomepageResume;


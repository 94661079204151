import './App.css';
import Navbar from './NavBar';
import HomepageResume from './HomePageResume';
import React from 'react';
// import TriggerAzureFunction from './incrementCounterWebsite'; 
import VisitorCounter from './incrementCounterWebsite';




function App() {
  <title>Brandon Torres Resume</title>
  // const btt = "Brandon Tye Torres";
  
  return (
    <div className="App">
      <VisitorCounter />
        <header className="Content">
        <Navbar />
        <HomepageResume /> 
 

      </header>
    </div>
  );
}

export default App;

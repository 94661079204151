import React, { useState, useEffect } from 'react';
import axios from 'axios';

function VisitorCounter() {
    const [count, setCount] = useState(0);

    // The URLs of our Azure Functions (Replace with your deployed function URLs)
    const getCountUrl = 'https://website-visited.azurewebsites.net/api/incrementTotalCount?id=INCREMENT';

    // const getCountUrl = "http://localhost:7071/api/incrementTotalCount?id=INCREMENT"
    // const incrementCountUrl = 'http://localhost:7071/api/incrementTotalCount?id=INCREMENT';

    // Fetch the visitor count when the component mounts
    useEffect(() => {
        async function fetchCount() {
            try {
                const response = await axios.get(getCountUrl);
                setCount(response.data); // Assuming the function returns the count directly
            } catch (error) {
                console.error("Error fetching visitor count:", error);
            }
        }

        fetchCount();
    }, [getCountUrl]);

    return (
        <div>
            <h2>Visitor Count: {count}</h2>
        </div>
    );
}

export default VisitorCounter;
















































// import React, { useState } from 'react';

// const TriggerAzureFunction = () => {
//   const [responseMessage, setResponseMessage] = useState(null);

//   const triggerAzureFunction = async () => {
//     try {
//       const functionUrl = 'https://visitor-counter-api.azurewebsites.net/api/incrementTotalCount?'; // Replace with your Azure Function URL
//       const requestOptions = {
//         method: 'POST',
//         headers: {
//           'id' : 'INCREMENT',
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ /* Your request data */ }),
//       };

//       const response = await fetch(functionUrl, requestOptions);
//       const data = await response.json();

//       // Set the response message in the component state
//       setResponseMessage(data.message); // Modify this based on your response structure
//     } catch (error) {
//       console.error('Error triggering Azure function:', error);
//     }
//   };

//   return (
//     <div>
//       <button onClick={triggerAzureFunction}>Trigger Azure Function</button>
//       {responseMessage && <p>Response from Azure Function: {responseMessage}</p>}
//     </div>
//   );
// };

// export default TriggerAzureFunction;







/**
 * 
 * def main(req: func.HttpRequest) -> func.HttpResponse:
    headers = {
        'Access-Control-Allow-Origin': 'https://www.brandontorresresume.com',  # Replace with your React app's URL
        'Access-Control-Allow-Methods': 'OPTIONS, POST',  # Add the allowed HTTP methods
        'Access-Control-Allow-Headers': 'Content-Type',
    }

    if req.method == 'OPTIONS':
        # For preflight requests, return a minimal response with the CORS headers
        return func.HttpResponse(status_code=204, headers=headers)

    # Your Azure Function logic here
    # ...

    # Return the response with CORS headers
    return func.HttpResponse(body='Your response body here', headers=headers)
 */